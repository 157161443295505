import React from "react";
import { Helmet } from "react-helmet";

function Visyon() {
  return (
    <div className="mt-20 h-screen text-2xl justify-center items-center text-center">
      <Helmet>
        <title>Visyonumuz</title>
      </Helmet>
      <div className="text-white mt-40">
        
        <br />
        Türkiye'nin en değerli ve en çok tercih edilen REKLAM rengi olmak.
      </div>
    </div>
  );
}

export default Visyon;
