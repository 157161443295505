import React from 'react'
import { Helmet } from 'react-helmet'

import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: '/imagesGalery/resim.jpeg',
    thumbnail:'/imagesGalery/resim.jpeg',
  },
  {
    original: '/imagesGalery/resim1.jpeg',
    thumbnail:'/imagesGalery/resim1.jpeg',
  },
  {
    original: '/imagesGalery/resim2.jpeg',
    thumbnail:'/imagesGalery/resim2.jpeg',
  },
  {
    original: '/imagesGalery/resim3.jpeg',
    thumbnail:'/imagesGalery/resim3.jpeg',
  },
  {
    original: '/imagesGalery/resim4.jpeg',
    thumbnail:'/imagesGalery/resim4.jpeg',
  },
  {
    original: '/imagesGalery/resim5.jpeg',
    thumbnail:'/imagesGalery/resim5.jpeg',
  },
  {
    original: '/imagesGalery/resim6.jpeg',
    thumbnail:'/imagesGalery/resim6.jpeg',
  },
  {
    original: '/imagesGalery/resim7.jpeg',
    thumbnail:'/imagesGalery/resim7.jpeg',
  },
  
  {
    original: '/imagesGalery/resim8.jpeg',
    thumbnail:'/imagesGalery/resim8.jpeg',
  },
  
  {
    original: '/imagesGalery/resim9.jpeg',
    thumbnail:'/imagesGalery/resim9.jpeg',
  },
  
  {
    original: '/imagesGalery/resim10.jpeg',
    thumbnail:'/imagesGalery/resim10.jpeg',
  },
  {
    original: '/imagesGalery/resim12.jpeg',
    thumbnail:'/imagesGalery/resim12.jpeg',
  },
  {
    original: '/imagesGalery/resim13.jpeg',
    thumbnail:'/imagesGalery/resim13.jpeg',
  },
  {
    original: '/imagesGalery/resim14.jpeg',
    thumbnail:'/imagesGalery/resim14.jpeg',
  },
  {
    original: '/imagesGalery/resim15.jpeg',
    thumbnail:'/imagesGalery/resim15.jpeg',
  },
  {
    original: '/imagesGalery/resim16.jpeg',
    thumbnail:'/imagesGalery/resim16.jpeg',
  },
  {
    original: '/imagesGalery/resim17.jpeg',
    thumbnail:'/imagesGalery/resim17.jpeg',
  },
  {
    original: '/imagesGalery/resim18.jpeg',
    thumbnail:'/imagesGalery/resim18.jpeg',
  },
  {
    original: '/imagesGalery/resim19.jpeg',
    thumbnail:'/imagesGalery/resim19.jpeg',
  },
  {
    original: '/imagesGalery/resim20.jpeg',
    thumbnail:'/imagesGalery/resim20.jpeg',
  },
  {
    original: '/imagesGalery/resim21.jpeg',
    thumbnail:'/imagesGalery/resim21.jpeg',
  },
  
  
 
];

function Services() {
  return (

    <div className='mt-16 grid'>
      <Helmet>
        <title>Hizmetlerimiz</title>
      </Helmet>
      <ImageGallery autoPlay items={images} />
    </div>
   
  )
}

export default Services