import React from 'react'
import { Helmet } from 'react-helmet'
import SocialMedia from '../Navbar/socialMedia'
import Footer from './Footer'
import "../.././App.css"
function Contact() {
  return (
    <>
    <div className='2xl:mr-80 2xl:-ml-20 xl:-ml-20  xl:flex 2xl:flex grid md:ml-80 sm:ml-28 black font-bold
     2xl:space-x-40 md:space-x-40 xl:space-x-40 space-x-10 justify-center items-center mt-28'>
      <Helmet>
        <title>İletişim</title>
      </Helmet>
      <div className='space-y-5 ml-10 text-white'>
        <h2 className='flex'>Adres:<br /> Evrenpaşa, 25200 46-48, 50. Yıl Cd.<br /> No:81/A,25200 Yakutiye/Erzurum</h2>
        <h2>Telefon:<br /><a href="tel:+905305448544" rel="preload">0530 544 85 44</a></h2>
      </div>
      <div className='mt-20 sm:max-w-screen-sm text-white  space-y-6 '>
        <h3 className='TContactItem1'>İletişim Hesaplarımız</h3>
        <div className='white'>< SocialMedia  /></div>
        <a className='TContactItem1' href='https://goo.gl/maps/tdqsj7ARaY1pDcrt7' target="_blank" rel="noreferrer">Konuma gitmek için tıklayınız.</a>
        <iframe className='grid MapItem -ml-5 ' title='mapLocation' src="https://www.google.com/maps/d/embed?mid=1iU7z6DP-NngOfAZg2Y8QsxViGp7TQHk&ehbc=2E312F" width="600" height="480"></iframe>
      </div>
    </div>
    <div className='mt-9'>
    <Footer/>
    </div>
    </>
  )
}

export default Contact