import { Menu, Transition } from '@headlessui/react'
import { Fragment} from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { AiFillHome,AiFillEye } from "react-icons/ai";
import { RiFocus2Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { FcAbout } from "react-icons/fc";


export default function Example() {
  return (
    <div className="w-64 text-right justify-center items-center">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md
           bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-orange-600 hover:text-green-600
            hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <NavLink className="flex gap-x-1" to="/">
            <AiFillHome fontSize={24} />
            ORANGE REKLAM
          </NavLink>
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0  mt-4 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-teal-400 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
              {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <FcAbout
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <FcAbout
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    <NavLink className="flex gap-x-1 " to="/about">
            BİZ KİMİZ
          </NavLink>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <RiFocus2Line className="mr-2 h-5 w-5"
                        aria-hidden="true"/>
                    <NavLink className="flex gap-x-1 " to="/mission">
            MİSYONUMUZ
          </NavLink>
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                   <AiFillEye className="mr-2 h-5 w-5"
                        aria-hidden="true"/>
                    <NavLink className="flex gap-x-1 Item" to="/visyon">
            VİSYONUMUZ
          </NavLink>
                  </button>
                )}
              </Menu.Item>
              
            </div>
            
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

