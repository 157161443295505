import React from "react";
import { Card } from "semantic-ui-react";
import "./About.css";

const aci = "/images/aci.png";
const inciAku="/images/inciAku.png";
const remax="/images/remax.png";
const bulutLife="/images/bulutLife.png";
const digiturk="/images/digiturk.png";
const suratOtomotiv="/images/suratOtomotiv.png";
const anadoluBoregi="/images/anadoluBoregi.png";
const lazerAkbulut="/images/lazerAkbulut.png";
const saglamEmlak="/images/saglamEmlak.png";
const beslerUn="/images/beslerUn.png";
const kılıclarInsaat="/images/kılıclarInsaat.png";
const doganGroup="/images/doganGroup.png";
const beypilic="/images/beypilic.png";
const turkcell="/images/turkcell.png";
const ptt="/images/ptt.png";
const cevreBakanligi="/images/cevreBakanligi.png";
const opet="/images/opet.png";
const erkal="/images/erkal.png";
const aziziyeBelediye="/images/aziziyeBelediye.png";
const vodafone="/images/vodafone.png";
const sok="/images/sok.png";
const ceyhunlar="/images/ceyhunlar.png";
const nuhLife="/images/nuhLife.png";
const kotan="/images/kotan.png";

const CardExampleColored = () => (
  <div className="grid  space-y-10">
    <Card.Group itemsPerRow={4}>
      <div className="flex  justify-center space-x-5">
        <Card color="red description" image={remax}  />
        <Card color="orange description" image={aci}  />
        <Card color="yellow description" image={inciAku}  />
        <Card color="olive description" image={bulutLife} />
      </div>
    </Card.Group>
    <Card.Group itemsPerRow={4}>
      <div className="flex  justify-center space-x-5">
        <Card color="red description" image={digiturk} />
        <Card color="orange description" image={suratOtomotiv} />
        <Card color="yellow description" image={anadoluBoregi} />
        <Card color="olive description" image={lazerAkbulut} />
      </div>
    </Card.Group>
    <Card.Group itemsPerRow={4}>
      <div className="flex  justify-center space-x-5">
        <Card color="red description" image={saglamEmlak} />
        <Card color="orange description" image={beslerUn} />
        <Card color="yellow description" image={kılıclarInsaat} />
        <Card color="olive description" image={doganGroup} />
      </div>
    </Card.Group>
    <Card.Group itemsPerRow={4}>
      <div className="flex  justify-center space-x-5">
        <Card color="red description" image={beypilic} />
        <Card color="orange description" image={turkcell} />
        <Card color="yellow description" image={cevreBakanligi} />
        <Card color="olive description" image={ptt} />
      </div>
    </Card.Group>
    <Card.Group itemsPerRow={4}>
      <div className="flex  justify-center space-x-5">
        <Card color="red description" image={opet} />
        <Card color="orange description" image={erkal} />
        <Card color="yellow description" image={aziziyeBelediye} />
        <Card color="olive description" image={vodafone} />
      </div>
    </Card.Group>
    <Card.Group itemsPerRow={4}>
      <div className="flex  justify-center space-x-5">
        <Card color="red description" image={sok} />
        <Card color="orange description" image={ceyhunlar} />
        <Card color="yellow description" image={nuhLife} />
        <Card color="olive description" image={kotan} />
      </div>
    </Card.Group>

  </div>
);
export default CardExampleColored;
