import React from 'react'
import { Helmet } from 'react-helmet'
import CardExampleColored from './CardExampleColored'
import "./About.css"

function References() {
  return (
    <><Helmet>
        <title>Referanslarımız</title>
      </Helmet>
    <div className='grid mr-2 ml-2 mb-10 mt-32 justify-center items-center'>
      <CardExampleColored/>
      
      </div>
    </>
  )
}

export default References