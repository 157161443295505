import { Route, Routes } from "react-router-dom";
import "./App.css";
import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import About from "./components/Pages/About";
import Home from "./components/Pages/Home";
import References from "./components/Pages/References";
import Services from "./components/Pages/Services";
import FadeLoader from "react-spinners/FadeLoader";
import Contact from "./components/Pages/Contact";
import Mission from "./components/Pages/Mission";
import Visyon from "./components/Pages/Visyon";
import NotFound from "./components/Pages/NotFound";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <div>
        {loading ? (
          <div className="Tloader mr-1 -mx-12 flex items-center justify-center h-screen">
            <FadeLoader size={120} color="orange" loading={loading} />
            <h1 className="mt-24 text-white -mx-32 font-extrabold">
              Hayata Getirdiğimiz Renk...
            </h1>
          </div>
        ) : (
          <>
            <div className="w-full flex-col space-y-18">
              <Navbar />
              <div className="grid ">
                <Routes>
                  <Route path="/*" element={<NotFound />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/references" element={<References />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/mission" element={<Mission />} />
                  <Route path="/visyon" element={<Visyon />} />
                </Routes>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default App;
