import React, { useEffect, useState } from 'react'
import '../../App.css'
import { CgScrollV } from 'react-icons/cg'
import SocialMedia from '../Navbar/socialMedia'


function Footer() {
  const [backToTopButton, setbackToTopButton] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setbackToTopButton(true)
      } else {
        setbackToTopButton(false)
      }
    })
  }, [])
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <>
      <div className="container  bg-stone-500 max-w-full  flex-col space-y-3">
 
        <div className='flex justify-center text-center mt-5 2xl:ml-44 items-center'><SocialMedia /></div>
        
        <p className='text-center pb-5 text-black justify-center  items-center'>
          ©2023 Orange Reklam <a className=' hover:bg-cyan-500 mx-2 ' href='https://www.linkedin.com/in/mkaraca25/' > Melik KARACA <br/></a> All rights reserved.
        </p>
      </div>
      <div className='flex '>
        <p className='darkmode text-white'>
          {backToTopButton && (<button style={{
            position: 'fixed',
            bottom: '30px',
            right: '3px',
            zIndex: '9999',
            height: '50px',
            width: '50px',
            fontSize: '50px',
          }} onClick={scrollUp}>
            <CgScrollV  />
          </button>)}
        </p>
      </div>

    </>



  )
}

export default Footer