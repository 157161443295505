import React from "react";
import { Helmet } from "react-helmet";

function Mission() {
  return (
    <div className="mt-20  h-screen text-2xl justify-center items-center text-center">
      <Helmet>
        <title>Misyonumuz</title>
      </Helmet>
      <div className="text-white mt-40">
       <br />
       En güncel teknolojileri kullanarak topluma renk katmak.
      </div>
    </div>
  );
}

export default Mission;
