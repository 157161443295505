import React from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import "../.././App.css";
import Contact from "./Contact";
import References from "./References";
import Services from "./Services";

// const images = [
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/91913501_2284392061867246_6384346070914170880_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=L8VeSJAbcU0AX9YLVSy&_nc_ht=scontent.ferz3-1.fna&oh=00_AfBo-m5tiPBnqriKnrMWtL0w37Zf_GZERCRnnQbpCNGN6A&oe=63A4756C",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/91913501_2284392061867246_6384346070914170880_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=L8VeSJAbcU0AX9YLVSy&_nc_ht=scontent.ferz3-1.fna&oh=00_AfBo-m5tiPBnqriKnrMWtL0w37Zf_GZERCRnnQbpCNGN6A&oe=63A4756C",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/91848718_2284391461867306_6539816443851046912_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=GV9UcLuix20AX-uGPBb&_nc_ht=scontent.ferz3-1.fna&oh=00_AfAeYpW4INEtJxWeZG_ag6VNB3cme4aepFe6tcVtVVWz7Q&oe=63A4733E",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/91848718_2284391461867306_6539816443851046912_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=GV9UcLuix20AX-uGPBb&_nc_ht=scontent.ferz3-1.fna&oh=00_AfAeYpW4INEtJxWeZG_ag6VNB3cme4aepFe6tcVtVVWz7Q&oe=63A4733E",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/81695221_2207846879521765_1138808624324280320_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=misc97nNwdIAX_r95Ar&_nc_ht=scontent.ferz3-1.fna&oh=00_AfDsuTQs4PV5cbfb0Dqnp_IfjSIVVR3KxsC_sKympA4YSQ&oe=63A464FF",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/81695221_2207846879521765_1138808624324280320_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=misc97nNwdIAX_r95Ar&_nc_ht=scontent.ferz3-1.fna&oh=00_AfDsuTQs4PV5cbfb0Dqnp_IfjSIVVR3KxsC_sKympA4YSQ&oe=63A464FF",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/68987120_2086030238370097_7453787477304672256_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=T-pBNoSlM0EAX-tCral&_nc_ht=scontent.ferz3-1.fna&oh=00_AfAY5S_hkwglUPDg-DUINs32Qlwyq7g-1FInqsOibuliLQ&oe=63A46BAB",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/68987120_2086030238370097_7453787477304672256_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=T-pBNoSlM0EAX-tCral&_nc_ht=scontent.ferz3-1.fna&oh=00_AfAY5S_hkwglUPDg-DUINs32Qlwyq7g-1FInqsOibuliLQ&oe=63A46BAB",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/64824127_2045610202412101_4172782335491571712_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=2oO1u5PsLtEAX-SFzCM&_nc_ht=scontent.ferz3-1.fna&oh=00_AfCOChZPoI61WbCuSuET-XZXEUPcIeFWxFSREQ1WP4iFWg&oe=63A461BF",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/64824127_2045610202412101_4172782335491571712_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=2oO1u5PsLtEAX-SFzCM&_nc_ht=scontent.ferz3-1.fna&oh=00_AfCOChZPoI61WbCuSuET-XZXEUPcIeFWxFSREQ1WP4iFWg&oe=63A461BF",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/57000845_2002919196681202_1192163418199556096_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=EkF6WinYEI8AX8RkCCk&_nc_ht=scontent.ferz3-1.fna&oh=00_AfDX4WbLTtEYAebC27ySnoZbyKhZI2SAMAzIoHFuZ7uYRw&oe=63A471BF",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/57000845_2002919196681202_1192163418199556096_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=EkF6WinYEI8AX8RkCCk&_nc_ht=scontent.ferz3-1.fna&oh=00_AfDX4WbLTtEYAebC27ySnoZbyKhZI2SAMAzIoHFuZ7uYRw&oe=63A471BF",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/56936275_2002463460060109_6060005155925917696_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=4Wrvu_B_FOAAX9N8NVF&_nc_ht=scontent.ferz3-1.fna&oh=00_AfD8tjXEob7_zv9yiQW0GdfTAEeWcTUy1nXehJoak3e0MA&oe=63A44B33",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.6435-9/56936275_2002463460060109_6060005155925917696_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=4Wrvu_B_FOAAX9N8NVF&_nc_ht=scontent.ferz3-1.fna&oh=00_AfD8tjXEob7_zv9yiQW0GdfTAEeWcTUy1nXehJoak3e0MA&oe=63A44B33",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.18169-9/30762992_1801911296781994_3091724315305650691_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=n5NYYmbLj60AX9V3aGj&_nc_ht=scontent.ferz3-1.fna&oh=00_AfACFsKjhrWnO8OYM97KC9lTXatzOX08M3F-SDG5nVDaqQ&oe=63A47A55",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.18169-9/30762992_1801911296781994_3091724315305650691_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=n5NYYmbLj60AX9V3aGj&_nc_ht=scontent.ferz3-1.fna&oh=00_AfACFsKjhrWnO8OYM97KC9lTXatzOX08M3F-SDG5nVDaqQ&oe=63A47A55",
//   },
//   {
//     original:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.18169-9/29594828_1793378464301944_4441290885633816240_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=PQJYrF7TSSwAX85z2EJ&_nc_ht=scontent.ferz3-1.fna&oh=00_AfCmCKDC7eUXGXUYTPLLEV45HWrKD7fU5m3PD1dukwba2A&oe=63A4670C",
//     thumbnail:
//       "https://scontent.ferz3-1.fna.fbcdn.net/v/t1.18169-9/29594828_1793378464301944_4441290885633816240_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=PQJYrF7TSSwAX85z2EJ&_nc_ht=scontent.ferz3-1.fna&oh=00_AfCmCKDC7eUXGXUYTPLLEV45HWrKD7fU5m3PD1dukwba2A&oe=63A4670C",
//   },
// ];

function Home() {
  return (
    <>
      <div className="grid   max-w-fit justify-center items-center ">
        <ReactPlayer
          playing="true"
          width="99vw"
          height="99vh"
          muted
          url="https://youtu.be/m70XXniW6vo"
        />
      </div>
      <h2 className="text-center font-extrabold mt-2 mb-4 text-white">HİZMETLERİMİZ</h2>
      <div className="mt-22 grid">
        <Services/>
      </div>
      <h2 className="text-center font-extrabold mt-5 -mb-20 text-white">
        REFERANSLARIMIZ
      </h2>
      <References />
      <div>
        <h2 className="text-center font-extrabold mt-5 text-white">İLETİŞİM</h2>
        <div className="-mt-16">
          <Contact />
        </div>
      </div>
      <Helmet>
        <title>Orange Reklam</title>
      </Helmet>
    </>
  );
}

export default Home;
