import React from 'react'
import "./About.css"
import { Helmet } from 'react-helmet'
function About() {
 
  return (
    <>
    <div className='relative grid justify-center h-full about mt-40 mr-96 w-screen 2xl:ml-64 xl:ml-40 lg:ml-10
    '>
      <Helmet>
        <title>Hakkımızda</title>
      </Helmet>
      <div className=' row-span-6 '>
      <h1 className='2xl:ml-80 xl:ml-80 lg:ml-80 font-bold md:ml-40 sm:ml-20 ml-10  text-emerald-700'>ORANGE REKLAM İLE HAYATA GETİRDİĞİMİZ RENK...</h1><br/>
      <p className='text-white'>
      Türkiye'nin dört bir yanına Erzurum da reklam hizmeti veren; dijital baskı işleri, tabela montajı, tabela üretimi, ışıklı ışıksız tabela imalatı, 
      araç kaplama,totem, kutu harf imalatı,<br/>  promosyon ürünleri satışı, kartvizit imalatı, promosyon basımı, broşür basımı ve reklam tanıtım işleri yapmaktayız.<br/>
       kaliteli malzemeler ile hazırladığımız ürünlerimiz işletmelerinize ve kullanım amaçlarınıza uygun olacak şekilde belirtmiş olduğunuz özelikleri en iyi şekilde taşımaktadır.
<br/><br/>
Reklam tanıtım alanında oldukça başarılı ekip ile birlikte çalışan firmamız uzun yıllardan beri birçok işletme sahibine ev sahipliği yapıp hizmetlerine yönelik<br/>
 reklam çalışmaları konusunda yardımcı olmaktadır. işinde profesyonel olan ekip arkadaşlarımız başta sizlerin fikir görüşleri olmak üzere sektör yeniliklerine de <br/>
 önem vererek dikkatli bir şekilde hareket etmektedir. yenilikçi olan çalışma arkadaşlarımız reklam tanıtımı, broşür kartvizit basımı, promosyon ürünleri, tabela, <br/>
 kutu harf, ışıklı ışıksız tabela imalatı ve araç kaplama işlerinde sizlerin yanında yer alarak tüm ihtiyaçlarınızı karşılamaktadır.
      </p>
    </div>
    
    </div>
    <div className='text-white justify-center mt-32 items-center gridAbout'>
      <div className=' justify-center items-center space-y-2'>
        <img class="ui medium circular centered image rounded-full description1" src="/images/logo.png" alt='img'/>
        <h3 className='ml-20 font-bold justify-center items-center'>HÜSEYİN GÜLER</h3>
        <h4 className='ml-4 text-white justify-center items-center'>KURUCU & GENEL KOORDİNATÖR</h4>
      </div>
      <div className=' justify-center items-center space-y-2'>
        <img class="ui medium circular centered image rounded-full description1" src="/images/logo.png" alt='img'/>
        <h3 className='ml-20  font-bold justify-center items-center'>AHMET TAŞTAN</h3>
        <h4 className='ml-8 text-white justify-center items-center'>KURUCU & MONTAJ USTASI</h4>
      </div>
      <div className=' justify-center items-center space-y-2'>
        <img class="ui medium circular centered image rounded-full description1" src="/images/logo.png" alt='img'/>
        <h3 className='ml-24 font-bold justify-center items-center'>ÖMER TAŞTAN</h3>
        <h4 className='ml-20 text-white justify-center items-center'>GRAFİK & TASARIM</h4>
      </div>
      <div className='mb-10 justify-center items-center space-y-2'>
        <img class="ui  medium circular centered image rounded-full description1" src="/images/logo.png" alt='img'/>
        <h3 className='ml-24 font-bold justify-center items-center text-white'>MONTAJ EKİBİ</h3>
      </div>
    </div></>
    
  )
}

export default About