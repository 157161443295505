import React, { useEffect, useState } from "react";
import { FcServices } from "react-icons/fc";
import { MdDarkMode, MdOutlineContactPhone } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import { NavLink } from "react-router-dom";
import "../.././App.css";
import Burger from "./Burger";
import Dropdown from "./Dropdown";
import SocialMedia from "./socialMedia";

function Navbar() {
  const [darkMode, setDarkMode] = useState(false);
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);
  return (
    <header>
      <div
        className="w-full flex h-fit THeader text-center  text-white  sm:space-x-5 2xl:space-x-10 
             z-40  fixed   items-center justify-center bg-slate-700 -mt-3 " 
      >
        <div
          className="transition-all w-full xl:-ml-20 md:-ml-20 text-opacity-80 space-x-4 mb-1
                    hover:text-opacity-100 2xl:-ml-64 items-center justify-center flex gap-x-8  font-semibold m-6 "
        > 
            <NavLink  to="/">
              <img className="md:ml-40 ml-64  xl:ml-40 w-20 h-16 rounded-full " src="/images/logo.png" alt="logo" />
            </NavLink>
          <div className=" NavLinkItem  mt-1"><Dropdown /></div>
          <NavLink className="flex gap-x-1 hover:rounded-md hover:bg-slate-500 hover:text-slate-900 NavLinkItem" to="/services">
            <FcServices fontSize={24} />
            HİZMETLERİMİZ
          </NavLink>
          <NavLink className="flex hover:rounded-md hover:bg-slate-500 hover:text-slate-900 gap-x-1 NavLinkItem" to="/references">
            <VscReferences fontSize={24} />
            REFERANSLARIMIZ
          </NavLink>
          <NavLink className="flex hover:rounded-md hover:bg-slate-500 hover:text-slate-900 gap-x-1 NavLinkItem" to="/contact">
            <MdOutlineContactPhone fontSize={24} />
            İLETİŞİM
          </NavLink>
        </div><div>
          <div className="NavLinkItem1 mt-10 2xl:mt-5 xl:mt-5 md:mt-5 sm:mt-5">
            <button
              className="position ml-40 bg-slate-700 hover:rounded-md  hover:bg-slate-500 hover:text-slate-900  relative darkButton rounded-2xl "
              onClick={() => setDarkMode(!darkMode)}
            >
              <MdDarkMode size={40}  />
            </button>
          </div>
        <div className="NavLinkItem -mt-10 2xl:mr-40 mr-28">
          <SocialMedia />
        </div>
        
        </div>
        <Burger/>
      </div>
    </header>
  );
}

export default Navbar;
