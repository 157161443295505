import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className=" h-screen text-center text-2xl text-white">
        <div className="mt-80">
        <h2>SAYFA BULUNAMADI!</h2>
        <h2>Sayfa kaldırılmış veya değiştirilmiş olabilir.<br/>
Anasayfaya dönmek için lütfen aşağıdaki linke tıklayın. <div className="mt-10 text-orange-500"><Link to="/">Orange Reklam</Link></div></h2>
        </div>
    </div>
  )
}
