import React from 'react'
import { AiFillInstagram } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { RiWhatsappFill } from 'react-icons/ri'

function SocialMedia() {
  return (
    <div className='flex gap-x-10 2xl:pr-40   font-extrabold'>
      <a className='hover:hover:rounded-md hover:bg-slate-500  rounded-xl' target="_blank" href="https://www.instagram.com/orangereklam/" rel="noopener noreferrer">{<AiFillInstagram size={32} />}</a>
      <a className='hover:hover:rounded-md hover:bg-slate-500  rounded-xl' target="_blank" href="https://ms-my.facebook.com/people/Orange-reklam/100063458554040/" rel="noopener noreferrer">{<BsFacebook size={28} />}</a>
      <a className='hover:hover:rounded-md hover:bg-slate-500  rounded-xl' target="_blank" href="https://wa.me/905305448544?
      text= Merhaba%20hizmetleriniz%20hakkında%20bilgi%20almak%20istiyorum." rel="noopener noreferrer">{<RiWhatsappFill size={32} />}</a>
    </div>
  )
}

export default SocialMedia