import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import SocialMedia from './socialMedia';
import Dropdown from "./Dropdown"


const Ul = styled.ul`{
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 20px 10px;
  }
  @media (min-width: 769px) {
    display:none;}
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;z-index:20;
    right: 0;
    height: 430px;
    width: 230px;
    padding-top: 5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
`;

const RightNav = ({ open,setOpen}) => {
  return (
    <>
    <Ul  open={open}>
      
      <li>
      <div  className="-ml-16" ><Dropdown /></div>
      </li>
      <li>
      <NavLink className="hover:rounded-md hover:bg-slate-500 hover:text-slate-900 -ml-11
                    " to="/services">HİZMETLERİMİZ</NavLink>
      </li>
      <li>
      <NavLink  className="hover:rounded-md hover:bg-slate-500 hover:text-slate-900 -ml-6
                    " to="/references">REFERANSLARIMIZ</NavLink>
      </li>
      <li>
      <NavLink  className="hover:rounded-md hover:bg-slate-500 hover:text-slate-900 -ml-24
                   " to="/contact">İLETİŞİM</NavLink>
      </li>
      <SocialMedia/>
    </Ul>
     </>
  )
}

export default RightNav